import React, { useState, useEffect } from "react";
import BlogPostCard from "../components/primitive/BlogPostCard";
import axios from "axios";

const BlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("all");
  const postsPerPage = 10;
  const loadingDiv = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
  ];

  const categorizedPosts = {
    politics: [],
    education: [],
  };

  useEffect(() => {
    axios
      .get("https://blog.coolidgesolutions.com/api/blogs", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setBlogPosts(response.data);
        console.log("blog post", response.data);
      });
  }, []);

  if (!blogPosts?.data) {
    // Loading state...
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts?.data?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const totalPosts = blogPosts?.data?.length;
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  blogPosts?.data?.forEach((post) => {
    if (post.label === 1) {
      categorizedPosts.politics.push(post);
    } else if (post.label === 2) {
      categorizedPosts.education.push(post);
    }
  });

  const renderPosts = (category) => {
    return categorizedPosts[category]?.map((post) => (
      <BlogPostCard key={post.id} post={post} />
    ));
  };

  if (!blogPosts?.data) {
    return (
      <div className="container mx-auto">
        <h1 className="pt-12 md:pt-24 text-3xl md:text-[4rem] text-black font-source-sans-pro-light">
          Blog Posts
        </h1>
        <div className="py-8 md:py-16 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4  md:gap-x-4 md:gap-y-6">
          {loadingDiv?.map((loading) => (
            <div className="rounded-3xl shadow animate-pulse" key={loading.id}>
              <div className="rounded-t-3xl">
                <div className="rounded-t-3xl w-full h-64 bg-slate-300" />
              </div>
              <div className="px-4 pt-6 pb-4 space-y-10">
                <div className="space-y-4">
                  <p className="w-[90%] text-base md:text-xl h-3 bg-slate-300"></p>
                  <p className="w-[60%] text-base md:text-xl h-3 bg-slate-300"></p>
                </div>
                <div className="space-y-3.5">
                  <p className="w-[100%] font-source-sans-pro-extralight text-base md:text-xl h-2 bg-slate-300"></p>
                  <p className="w-[80%] font-source-sans-pro-extralight text-base md:text-xl h-2 bg-slate-300"></p>
                  <p className="w-[60%] font-source-sans-pro-extralight text-base md:text-xl h-2 bg-slate-300"></p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xs md:text-sm px-3 py-2 rounded-xl h-9 w-20 bg-slate-300"></div>
                  <p className="text-xs md:text-sm font-source-sans-pro-extralight h-5 w-24 bg-slate-300"></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <h1 className="pt-12 md:pt-24 text-3xl md:text-[4rem] text-black font-source-sans-pro-light">
        Blog Posts
      </h1>
      {blogPosts?.data.length < 1 ? (
        <p className="py-12">No blog post available</p>
      ) : (
        <div>
          <div className="flex space-x-4 pt-12 pb-10">
            <button
              className={`px-3.5 py-1.5 border font-source-sans-pro-extralight ${
                currentTab === "all" ? "bg-VeryLightPurple " : "border-gray-300"
              } rounded`}
              onClick={() => setCurrentTab("all")}
            >
              All
            </button>
            <button
              className={`px-3.5 py-1.5 border font-source-sans-pro-extralight ${
                currentTab === "politics"
                  ? "bg-VeryLightPurple "
                  : "border-gray-300"
              } rounded`}
              onClick={() => setCurrentTab("politics")}
            >
              Politics
            </button>
            <button
              className={`px-3.5 py-1.5 border font-source-sans-pro-extralight ${
                currentTab === "education"
                  ? "bg-VeryLightPurple "
                  : "border-gray-300"
              } rounded`}
              onClick={() => setCurrentTab("education")}
            >
              Education
            </button>
          </div>
          <div className="pb-8 md:pb-16 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-x-4 md:gap-y-6">
            {currentTab === "all" &&
              currentPosts?.map((post) => (
                <BlogPostCard key={post.id} post={post} />
              ))}
            {currentTab === "politics" && renderPosts("politics")}
            {currentTab === "education" && renderPosts("education")}
          </div>
          <div className="flex justify-center mt-8">
            {blogPosts?.data?.length > postsPerPage && (
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3.5 py-1.5 border border-gray-300 rounded"
                >
                  &#171;
                </button>
                <div>
                  {totalPosts > postsPerPage && (
                    <div className="flex justify-center space-x-2">
                      {pageNumbers.map((number) => (
                        <button
                          key={number}
                          onClick={() => paginate(number)}
                          className={`px-3.5 py-1.5 border font-source-sans-pro-extralight ${
                            number === currentPage
                              ? "bg-gray-300"
                              : "border-gray-300"
                          } rounded`}
                        >
                          {number}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastPost >= blogPosts?.data?.length}
                  className="px-3.5 py-1.5 border border-gray-300 rounded"
                >
                  &#187;
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPosts;
