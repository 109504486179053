import React from "react";
import cs_footer_log from "../../assets/icons/cs-footer-logo.svg";
import footer_line from "../../assets/icons/footer-line.svg";
import facebook_icon from "../../assets/icons/facebook-icon.svg";
import mail_img from "../../assets/icons/mail.png";
import linkedin_icon from "../../assets/icons/linkedin-icon.svg";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import { Link } from "react-scroll";
// import { Scroll } from "react-scroll";
// const ScrollLink = Scroll.ScrollLink;

const Footer = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  // const navigateToContactUs = () => {
  //   navigate("/contact-us");
  // };

  return (
    <div>
      <div className="px-[1.5rem] lg:px-0 lg:container lg:mx-auto pt-20 pb-20 lg:py-0">
        <div className="lg:hidden">
          <h3 className="mb-3">Connect with us</h3>
          <div className="flex flex-row items-center space-x-3">
            <a
              href="https://web.facebook.com/CoolidgeSolutions"
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-Magnolia border border-PaleMauve "
            >
              {" "}
              <img
                src={facebook_icon}
                className="px-2.5 py-3 w-10 h-10"
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/coolidge-solutions/about/"
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-Magnolia border border-PaleMauve "
            >
              {" "}
              <img
                src={linkedin_icon}
                className="px-2.5 py-3 w-10 h-10"
                alt=""
              />
            </a>
            <a
              href="mailto:contact@coolidgesolutions.com"
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-Magnolia border border-PaleMauve "
            >
              {" "}
              <img src={mail_img} className="p-2 w-10 h-10" alt="" />
            </a>
            {/* <Link
              to=""
              className="rounded-full bg-Magnolia border border-PaleMauve "
            >
              <img src={facebook_icon} className="p-2.5 w-10 h-10 " alt="" />
            </Link>
            <Link
              to=""
              className="rounded-full bg-Magnolia border border-PaleMauve "
            >
              <img src={linkedin_icon} className="p-2.5 w-10 h-10" alt="" />
            </Link> */}
          </div>
        </div>
        <div className="flex flex-col-reverse space-y-6 lg:space-y-0 lg:flex-row">
          <div className="w-full lg:w-[35%] space-y-4 lg:space-y-8 lg:pt-24 lg:mt-0">
            <img src={cs_footer_log} className="w-24 lg:w-fit" alt="" />
            <p className="font-source-sans-pro-extralight text-xs lg:text-sm">
              Copyright © {new Date().getFullYear()} Coolidge Solutions
            </p>
          </div>
          <div className="w-full lg:w-[65%] flex flex-col space-y-6 lg:space-y-0 lg:flex-row">
            <div className="w-full lg:w-[75%] flex flex-row space-y-6 lg:space-y-0 lg:flex-row lg:justify-between mb-8 lg:mb-0">
              <div className="hidden lg:block ">
                <img src={footer_line} alt="" />
              </div>
              <div className="text-sm lg:text-base flex flex-col space-y-2.5 font-source-sans-pro-extralight lg:pt-24 pr-20 lg:pr-0">
                <Link
                  to="/"
                  className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100"
                >
                  Home
                </Link>
                {location.pathname === "/" ? (
                  <HashLink
                    to="/#about-us"
                    smooth={true}
                    duration={500}
                    className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100"
                  >
                    About us
                  </HashLink>
                ) : (
                  <Link
                    to="/#about-us"
                    smooth={true}
                    duration={500}
                    className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100"
                  >
                    About us
                  </Link>
                )}

                {location.pathname === "/" ? (
                  <HashLink
                    to="/#our-services"
                    smooth={true}
                    duration={500}
                    className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100"
                  >
                    Our Services
                  </HashLink>
                ) : (
                  <Link
                    to="/#our-services"
                    smooth={true}
                    duration={500}
                    className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100"
                  >
                    Our Services
                  </Link>
                )}
                <span className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100">
                  Careers
                </span>
                <Link
                  to="/contact-us"
                  className="w-fit cursor-pointer hover:border-b hover:border-HoneyFlower transition-all duration-100"
                >
                  Contact Us
                </Link>
              </div>
              <div className="text-sm lg:text-base flex flex-col lg:pt-24">
                <h3 className="mb-4">Address</h3>
                <div className="flex flex-col space-y-2 mb-4 lg:mb-10 font-source-sans-pro-extralight">
                  <span>Suite 1900, Exchange Tower, 130 King St W,</span>
                  <span>Toronto, M5X A2A, Ontario</span>
                </div>
                {/* <div className="flex flex-col space-y-2 font-source-sans-pro-extralight">
                  <span>14B, Kafayat Abdulrasaq Street,</span>
                  <span>Lekki, Lagos, Nigeria.</span>
                </div> */}
              </div>
              <div className="hidden lg:block">
                <img src={footer_line} alt="" />
              </div>
            </div>
            <div className="flex items-start justify-between space-x-4 w-full lg:w-[25%] lg:pl-4 lg:pt-24 ">
              <div className="hidden lg:block">
                <h3 className="mb-3">Connect with us</h3>
                <div className="flex flex-row items-center space-x-3">
                  <a
                    href="https://web.facebook.com/CoolidgeSolutions"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full bg-Magnolia border border-PaleMauve "
                  >
                    {" "}
                    <img
                      src={facebook_icon}
                      className="px-2.5 py-3 w-10 h-10"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/coolidge-solutions/about/"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full bg-Magnolia border border-PaleMauve"
                  >
                    {" "}
                    <img
                      src={linkedin_icon}
                      className="px-2.5 py-3 w-10 h-10"
                      alt=""
                    />
                  </a>
                  <a
                    href="mailto:contact@coolidgesolutions.com"
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full bg-Magnolia border border-PaleMauve "
                  >
                    {" "}
                    <img src={mail_img} className="p-2 w-10 h-10" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
