import React from "react";
import why_should_be_your_partner_body_img from "../../assets/images/why-we-should-be-your-partner-body-img.png";

const WhyWeShouldBeYourPartner = () => {
  return (
    <div className="why_we_should_be_your_partner_bg text-white py-16 md:py-64">
      <div className="container mx-auto">
        <div className="flex justify-center md:pb-20">
          <h1 className="max-w-[16rem] md:max-w-fit text-3xl md:text-[4rem] text-white text-center font-source-sans-pro-extralight">
            Why We Should Be Your Partner
          </h1>
        </div>
        <div className="flex flex-col-reverse items-center space-y-6 md:space-y-0 md:flex-row md:space-x-20">
          <div className="w-full md:w-[45%] mt-10 md:mt-0">
            <img
              src={why_should_be_your_partner_body_img}
              alt="why_should_be_your_partner_body_img"
            />
          </div>
          <div className="w-full md:w-[55%] space-y-10 lg:space-y-16 ">
            <div className="space-y-4">
              <h4 className="text-center md:text-left text-base md:text-2xl">
                We become your trusted partner
              </h4>
              <p className="text-center md:text-left font-source-sans-pro-extralight leading-7">
                As a trusted partner, we are committed to building lasting
                relationships based on integrity, transparency, and mutual
                success. Our unwavering dedication to your business objectives
                means that we go above and beyond to understand your unique
                challenges and tailor solutions that align with your vision.
              </p>
            </div>
            <div className="space-y-4">
              <h4 className="text-center md:text-left text-base md:text-2xl">
                We are industry leaders
              </h4>
              <p className="text-center md:text-left font-source-sans-pro-extralight leading-7">
                Our leading expertise allows us to provide strategic guidance
                that goes beyond the surface level. We delve deep into your data
                dynamics to uncover unique insights that drive innovation and
                competitive advantage. We stay ahead of the curve to ensure our
                cybersecurity services aligns with the demands of the digital
                age.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyWeShouldBeYourPartner;
