import React from "react";
import home_hero_img from "../../assets/images/home-hero-img.png";

const HomeHero = () => {
  return (
    <div className="py-10 md:py-16 space-y-6 md:space-y-12">
      <div className="space-y-4">
        <div className="flex justify-center">
          <h1 className="max-w-sm md:max-w-5xl text-center text-3xl md:text-7xl text-white font-source-sans-pro-extralight">
            Empowering Your Business with Solutions in Cybersecurity and Data
            Analytics
          </h1>
        </div>
        <div className="flex justify-center">
          <p className="max-w-xs md:max-w-fit text-lg md:text-xl text-white text-center font-source-sans-pro-extralight">
            Protect your digital assets and access data insights for business
            success
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <img
          src={home_hero_img}
          className="w-[80%] md:w-[55%]"
          alt="home hero"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default HomeHero;
