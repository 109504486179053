import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { useBlogPost } from "../context/BlogPostContext";
import Navbar from "../components/connected/Navbar";
import Footer from "../components/sections/Footer";

const BlogMain = () => {
  const { id } = useParams(); // Get the blog ID
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setBlogPost, blogPost } = useBlogPost();
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToOurServices = () => {
    if (ourServicesRef.current) {
      ourServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Fetch blog post based on ID
  const getBlogPost = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://blog.coolidgesolutions.com/api/blogs/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setBlogPost(response?.data[0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog post:", error);
    }
  };

  useEffect(() => {
    getBlogPost();
  }, [id]);

  useEffect(() => {
    if (blogPost) {
      // After fetching the blog, redirect to the human-readable URL
      const userFriendlyUrl = blogPost.title
        ?.replace(/\s+/g, "-")
        .toLowerCase();
      navigate(`/blog/${id}/${userFriendlyUrl}`, { replace: true });
    }
  }, [blogPost, navigate, id]);

  // Show a loading state while fetching the blog

  if (loading) {
    return (
      <div>
        <div className="bg-#391647">
          <Navbar />
        </div>
        <div className="container mx-auto py-8 md:py-32 space-y-6 animate-pulse">
          <div className="flex flex-col items-center justify-center space-y-4">
            <p className="text-3xl md:text-5xl text-center h-5 w-[80%] bg-slate-300"></p>
            <p className="text-3xl md:text-5xl text-center h-5 w-[60%] bg-slate-300"></p>
          </div>
          <div className="w-full">
            <div className="w-full h-56 md:h-96 rounded-2xl bg-slate-300"></div>
            <div className="flex items-center justify-between py-1.5 md:py-3">
              <div className="w-24 h-10 bg-slate-300  rounded-3xl"></div>
            </div>
          </div>
          <div className="space-y-2.5">
            <p className="w-full h-3 bg-slate-300"></p>
            <p className="w-[95%] h-3 bg-slate-300"></p>
            <p className="w-[90%] h-3 bg-slate-300"></p>
            <p className="w-[85%] h-3 bg-slate-300"></p>
            <p className="w-[80%] h-3 bg-slate-300"></p>
            <p className="w-[75%] h-3 bg-slate-300"></p>
            <p className="w-[70%] h-3 bg-slate-300"></p>
            <p className="w-[65%] h-3 bg-slate-300"></p>
            <p className="w-[60%] h-3 bg-slate-300"></p>
            <p className="w-[55%] h-3 bg-slate-300"></p>
            <p className="w-[50%] h-3 bg-slate-300"></p>
            <p className="w-[45%] h-3 bg-slate-300"></p>
            <p className="w-[40%] h-3 bg-slate-300"></p>
            <p className="w-[35%] h-3 bg-slate-300"></p>
            <p className="w-[30%] h-3 bg-slate-300"></p>
          </div>
        </div>
        <Footer
          scrollToAboutUs={scrollToAboutUs}
          scrollToOurServices={scrollToOurServices}
        />
      </div>
    );
  }

  return (
    <div>
      {/* Nested route will be rendered here */}
      <Outlet />
    </div>
  );
};

export default BlogMain;
