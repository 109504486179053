import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/sections/Footer";
import Navbar from "../components/connected/Navbar";
import ReactHtmlParser from "html-react-parser";
import share_icon from "../assets/icons/share-icon.png";
import whatsapp_icon from "../assets/icons/WhatsApp_icon.png";
import facebook_logo from "../assets/icons/Facebook_Logo.png";
import twitter from "../assets/icons/twitter.jpg";
import { useBlogPost } from "../context/BlogPostContext";
import { Helmet } from "react-helmet-async";

const BlogPostDetails = ({ post }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);
  // const { id } = useParams();
  const { blogPost } = useBlogPost();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToOurServices = () => {
    if (ourServicesRef.current) {
      ourServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const postTitle = blogPost && blogPost?.title;
  const postUrl = blogPost
    ? `https://coolidgesolutions.com/blog/${blogPost.id}`
    : // ? `https://coolidge-solutions.netlify.app/blog/${blogPost[0]?.id}`
      "";

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    postUrl
  )}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${postTitle}: ${postUrl}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    postUrl
  )}&text=${encodeURIComponent(postTitle)}`;

  return (
    <div>
      {blogPost && (
        <Helmet>
          <title>{blogPost?.title} | Coolidge Solutions</title>
        </Helmet>
      )}
      <div className="bg-#391647">
        <Navbar />
      </div>
      <div className="container mx-auto py-8 md:py-24 space-y-6">
        <h3 className="text-3xl md:text-5xl text-center">{blogPost?.title}</h3>
        <div className="w-full">
          <div className="relative">
            <img
              src={blogPost?.file}
              className="w-full h-56 md:h-96 object-cover rounded-2xl"
              alt={blogPost?.title}
            />
            <span
              className="font-source-sans-pro-extralight text-xs text-white bg-black py-1 px-2 rounded-lg absolute top-4
         right-4"
            >
              {blogPost?.label === 1
                ? "Politics"
                : blogPost?.label === 2
                ? "Education"
                : ""}
            </span>
          </div>
          <div className="flex items-center justify-between py-1.5 md:py-3">
            <div className="flex items-center justify-center">
              <div className="relative mr-56">
                <div
                  className={`absolute inset-y-0 left-1/2 h-10 flex items-center pl-16 pr-3 space-x-2 bg-white rounded-tr-full rounded-br-full ${
                    isMenuOpen ? "" : "hidden"
                  }`}
                >
                  <a
                    href={facebookShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
                  >
                    <img src={facebook_logo} alt="" />
                  </a>
                  <a
                    href={twitterShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
                  >
                    <img src={twitter} alt="" />
                  </a>
                  <a
                    href={whatsappShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
                  >
                    <img src={whatsapp_icon} alt="" />
                  </a>
                </div>
                <button
                  className={`relative h-10 w-24 px-2 space-x-1 inline-flex items-center justify-center bg-VeryLightPurple hover:bg-opacity-80 rounded-tl-full rounded-bl-full focus:outline-none transition-all duration-500 ${
                    isMenuOpen
                      ? "bg-VeryLightPurple hover:bg-opacity-80"
                      : "rounded-tr-full rounded-br-full"
                  }`}
                  onClick={toggleMenu}
                >
                  <img src={share_icon} className="w-6" alt="" />
                  <span>Share</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>{blogPost && ReactHtmlParser(blogPost?.description)}</div>
        <div className="w-fit">
          <div className="relative mr-56">
            <div
              className={`absolute inset-y-0 left-1/2 h-10 flex items-center pl-16 pr-3 space-x-2 bg-white rounded-tr-full rounded-br-full ${
                isMenuOpen ? "" : "hidden"
              }`}
            >
              <a
                href={facebookShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
              >
                <img src={facebook_logo} alt="" />
              </a>
              <a
                href={twitterShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
              >
                <img src={twitter} alt="" />
              </a>
              <a
                href={whatsappShareUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-Magnolia p-3 h-10 w-10 inline-flex items-center justify-center text-blue-600 hover:bg-white hover:bg-opacity-5 rounded-full"
              >
                <img src={whatsapp_icon} alt="" />
              </a>
            </div>
            <button
              className={`relative h-10 w-24 px-2 space-x-1 inline-flex items-center justify-center bg-VeryLightPurple hover:bg-opacity-80 rounded-tl-full rounded-bl-full focus:outline-none transition-all duration-500 ${
                isMenuOpen
                  ? "bg-VeryLightPurple hover:bg-opacity-80"
                  : "rounded-tr-full rounded-br-full"
              }`}
              onClick={toggleMenu}
            >
              <img src={share_icon} className="w-6" alt="" />
              <span>Share</span>
            </button>
          </div>
        </div>
      </div>
      <Footer
        scrollToAboutUs={scrollToAboutUs}
        scrollToOurServices={scrollToOurServices}
      />
    </div>
  );
};

export default BlogPostDetails;
