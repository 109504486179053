import React, { useState, useEffect } from "react";
import BlogPostCard from "../primitive/BlogPostCard";
import axios from "axios";
import { Link } from "react-router-dom";

const BlogPostSection = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const loadingDiv = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
  ];

  useEffect(() => {
    axios
      .get("https://blog.coolidgesolutions.com/api/blogs", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setBlogPosts(response.data);
        console.log("blog post", response.data);
      });
  }, []);

  if (!blogPosts?.data) {
    return (
      <div>
        <div className="container mx-auto flex items-center justify-between">
          <h1 className="max-w-[16rem] md:max-w-fit text-3xl md:text-[4rem] text-center text-black font-source-sans-pro-light">
            Recent Posts
          </h1>
          <Link className="hover:underline" to="/blog">
            View All
          </Link>
        </div>
        <div className="container mx-auto my-4 md:my-10 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4  md:gap-x-4 md:gap-y-6">
          {loadingDiv?.map((loading) => (
            <div className="rounded-3xl shadow animate-pulse" key={loading.id}>
              <div className="rounded-t-3xl">
                <div className="rounded-t-3xl w-full h-64 bg-slate-300" />
              </div>
              <div className="px-4 pt-6 pb-4 space-y-10">
                <div className="space-y-4">
                  <p className="w-[90%] text-base md:text-xl h-3 bg-slate-300"></p>
                  <p className="w-[60%] text-base md:text-xl h-3 bg-slate-300"></p>
                </div>
                <div className="space-y-3.5">
                  <p className="w-[100%] font-source-sans-pro-extralight text-base md:text-xl h-2 bg-slate-300"></p>
                  <p className="w-[80%] font-source-sans-pro-extralight text-base md:text-xl h-2 bg-slate-300"></p>
                  <p className="w-[60%] font-source-sans-pro-extralight text-base md:text-xl h-2 bg-slate-300"></p>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xs md:text-sm px-3 py-2 rounded-xl h-9 w-20 bg-slate-300"></div>
                  <p className="text-xs md:text-sm font-source-sans-pro-extralight h-5 w-24 bg-slate-300"></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (blogPosts?.data.length > 0) {
    return (
      <div className="container mx-auto ">
        <div className="flex items-center justify-between">
          <h1 className="max-w-[16rem] md:max-w-fit text-3xl md:text-[4rem] text-center text-black font-source-sans-pro-light">
            Recent Posts
          </h1>
          <Link className="hover:underline" to="/blog">
            View All
          </Link>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 my-4 md:my-10">
          {blogPosts?.data?.slice(0, 3).map((post) => (
            <BlogPostCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    );
  }
};

export default BlogPostSection;
