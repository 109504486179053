import React, { createContext, useContext, useState } from "react";

// Create a context to hold the blog post data
const BlogPostContext = createContext();

export const useBlogPost = () => {
  return useContext(BlogPostContext);
};

export const BlogPostProvider = ({ children }) => {
  const [blogPost, setBlogPost] = useState(null);

  return (
    <BlogPostContext.Provider value={{ blogPost, setBlogPost }}>
      {children}
    </BlogPostContext.Provider>
  );
};
